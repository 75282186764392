/* eslint-disable */
const styles = {
  formattedHeader: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid #e5e5e5',
    display: 'flex',
    ['@media (min-width: 600px)']: {
      padding: '0 53.25px'
    },
    ['@media (min-width: 1200px)']: {
      padding: '0 122.5px'
    }
  },
  alliance: {
    width: '80px', 
    height: '40px',
    padding: '8px 0px'
  },
  experience: {
    width: '80px',
    height: '40px', 
    padding: '8px 0px'
  }
};

export default styles;