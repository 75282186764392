import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid
} from '@material-ui/core';
import styles from './styles';
import Title from '../../atoms/Title/title';
import TextDescription from '../../atoms/TextDescription/textDescription';
import Ticket from '../../molecules/Ticket/ticket';
import DefaultContainer from '../../organisms/DefaultContainer/default-container';
import getCoupon from '../../../clients/xewardsDeliveryCoupon/get';
import phoneUtils from '../../../utils/phone';
import * as AnalyticsManager from '../../../analytics/analyticsManager';
import * as eventTypes from '../../../analytics/eventTypes';

const useStyles = makeStyles(styles);

const Status = ({ match }) => {
  const [couponState, setCouponState] = useState({});
  const [pageState, setPageState] = useState({});
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [couponCode] = useState(match.params.couponCode);
  const [hasAddedToAmplitude, setHasAddedToAmplitude] = useState(false);
  const [errorCoupon, setErrorCoupon] = useState();
  const classes = useStyles();

  useEffect(() => {
    const saveDataAnalytics = (statusCoupon) => {
      if (!hasAddedToAmplitude) {
        const dataAnalytics = AnalyticsManager.getDataFromSessionStorage();
        const extraData = { statusCoupon };

        AnalyticsManager.saveDataAnalytics(
          dataAnalytics,
          eventTypes.COUPON_STATUS_PAGE_VIEWED,
          extraData
        );
        setHasAddedToAmplitude(true);
      }
    };
    const loadContext = async () => {
      const { status, data } = await getCoupon(couponCode);
      if (status === 200) {
        const { name: alliance } = data._embedded.alliance;
        const { asset } = data.bucket._embedded;
        const { consumer } = data._embedded;
        const mobileCouponReceived = phoneUtils.applyMobileMask(data.deliveredMobile);
        setCouponState((previousState) => ({
            ...previousState,
            mobileCouponReceived,
            title: asset.ticket.title,
            unit: asset.ticket.unit,
            value: asset.ticket.value.toString(),
            expirationDate: data.expirationDate,
            couponCode: data.coupon,
            name: data.status.detail && data.status.detail.name,
            activateDate: moment(data.status.timestamp).format('DD/MM/YYYY').toString(),
            alliance: data._embedded.alliance.name,
            logo: asset.ticket.logo
        }));

        const statusCoupon = data.status && data.status.name;
        saveDataAnalytics(statusCoupon);
        if (data.status.name === 'redeemed') {
          setRedirectToHome(true);
        }
        setPageState((previousState) => {
          let title = '';
          let description = '';
          let details = '';
          let list = false;

          if (data.status.name === 'expired') {
            title = 'Voucher expirado';
            const expirationDate = moment(data.expirationDate).format('DD/MM/YYYY').toString();
            description = 'Infelizmente não é possível ativar este voucher,'
              + ' pois está fora da data de validade.'
              + `<br><br> <b>Data de validade: ${expirationDate}</b>`;
          } else if (data.status.name === 'dispatched') {
            description = `<b>Estamos trabalhando na sua ativação.</b><br> Em até 48h teremos a confirmação
              e você já poderá utilizar seu voucher de ${couponState.title}`
              + `<br><br> NÚMERO ${alliance?.toUpperCase()} INFORMADO`
              + `<br><br> <b>${mobileCouponReceived}</b>`;
          } else if (data.status.name === 'issued') {
            title = 'Deu tudo certo!';
            description = `${consumer.name ? `<b>Parabéns ${consumer.name}</b>, ` : '<b>Parabéns</b>, '
               }o seu voucher foi ativado com sucesso no número informado e já está disponível para uso.`;
            details = 'NÚMERO ATIVADO'
              + `<b>${mobileCouponReceived}</b>`;
            list = true;
          }
          return {
            ...previousState,
            title,
            description,
            details,
            list
          };
        });
      } else {
        setErrorCoupon(`/error/retryGetCoupons/coupons/${match.params.couponCode}`);
      }
    };
    loadContext();
  }, [couponCode, hasAddedToAmplitude, couponState.title, match.params.couponCode]);

  if (redirectToHome) {
    return (<Redirect to={`/coupons/${couponCode}`} />);
  }

  if (errorCoupon) {
    return (<Redirect to={`/error/retryGetCoupons/coupons/${match.params.couponCode}`} />);
  }

  const getList = () => (
    <List className={classes.list}>
      <ListItem alignItems='flex-start' className={classes.listItem}>
        <Grid item xs={6}>
          <ListItemText
            className={classes.listItemPrimary}
            disableTypography={true}
            primary='NÚMERO ATIVADO'
          />
        </Grid>
        <Grid item xs={6}>
          <ListItemText
            className={classes.listItemSecondary}
            disableTypography={true}
            primary={couponState.mobileCouponReceived}
          />
        </Grid>
      </ListItem>
      <Divider component='li' />
      <ListItem alignItems='flex-start' className={classes.listItem}>
        <Grid item xs={6}>
          <ListItemText
            className={classes.listItemPrimary}
            disableTypography={true}
            primary='ATIVADO EM'
          />
        </Grid>
        <Grid item xs={6}>
          <ListItemText
            className={classes.listItemSecondary}
            disableTypography={true}
            primary={couponState.activateDate}
          />
        </Grid>
      </ListItem>
      <Divider component='li' />
      <div style={{ paddingTop: '16px', fontSize: '14px' }}>
        Bônus válido por até <b>30 dias</b>.
      </div>
    </List>
  );

  const getCenter = () => (
    <div className={classes.ticketStyle}>
      <Grid
        container
        justify='center'
        style={{
          backgroundColor: '#f5f5f5',
          padding: '16px',
          width: '100%'
        }}
      >
        <Grid item lg={6} md={6} sm={8} xs={12}>
          <Ticket
            size='small'
            title={couponState.title}
            unit={couponState.unit}
            value={couponState.value}
            expirationDate={couponState.expirationDate}
            couponCode={couponState.couponCode}
            logo={couponState.logo}
          />
        </Grid>
      </Grid>
    </div>
  );
  const getBottom = () => (
    <>
      <div style={{ padding: '0px' }}>
        <Title title={pageState.title} />
        {pageState.description ? <TextDescription content={pageState.description} /> : null}
        {
          pageState.list ? getList() : null
        }
      </div>
    </>
  );
  return (
    <DefaultContainer
      centerContent={getCenter()}
      bottomContent={getBottom()}
    />
  );
};

Status.propTypes = {
  match: PropTypes.object.isRequired
};

export default Status;
