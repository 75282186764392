import React from 'react';
import Proptypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Grid
} from '@material-ui/core';

import SVG from 'react-inlinesvg';
import styles from './styles';

const useStyles = makeStyles(styles);

const Header = ({ alliance, experience }) => {
  const isNotFoundURL = window.location.href.includes('/notfound');

  const classes = useStyles();
  return (
    <div>
      {
        !isNotFoundURL && (
          <AppBar position='static' className={classes.formattedHeader}>
            <Toolbar>
              <Grid
                container
                direction='row'
                justify='space-between'
                alignItems='baseline'
              >
                <SVG src={alliance} className={classes.alliance} />
                <SVG src={experience} className={classes.experience} />
              </Grid>
            </Toolbar>
          </AppBar>
        )
      }
    </div>
  );
};

Header.propTypes = {
  alliance: Proptypes.string.isRequired,
  experience: Proptypes.string.isRequired
};

export default Header;
