import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '../../atoms/Button/button';
import TextDescription from '../../atoms/TextDescription/textDescription';
import DefaultContainer from '../../organisms/DefaultContainer/default-container';
import xewardsCouponPost from '../../../clients/xewardsDeliveryCoupon/post';
import phoneUtils from '../../../utils/phone';
import * as AnalyticsManager from '../../../analytics/analyticsManager';
import * as eventTypes from '../../../analytics/eventTypes';
import styles from './styles';

const useStyles = makeStyles(styles);

const Confirmation = ({ match }) => {
  const classes = useStyles();

  const [hasAddedToAmplitude, setHasAddedToAmplitude] = useState(false);
  const provider = sessionStorage.getItem('allianceName');
  const mobile = sessionStorage.getItem('mobile');
  const formattedPhone = phoneUtils.applyMobileMask(mobile);
  const [screenState, setScreenState] = useState({
    couponProvider: provider,
    mobile,
    coupon: match.params.couponCode,
    formattedMobile: formattedPhone,
    shouldRedirec: false
  });

  const redirectToInput = () => {
    sessionStorage.removeItem('mobile');
    setScreenState((previousState) => ({
      ...previousState,
      shouldRedirect: true,
      redirectPath: `/phonenumber/coupons/${screenState.coupon}`
    }));
    sendDataAnalytics(eventTypes.CHANGE_NUMBER_BUTTON_CLICKED);
  };

  const sendDataAnalytics = (eventType) => {
    const dataAnalytics = AnalyticsManager.getDataFromSessionStorage();

    AnalyticsManager.saveDataAnalytics(
      dataAnalytics,
      eventType
    );
  };

  if (!hasAddedToAmplitude) {
    sendDataAnalytics(eventTypes.COUPON_ACTIVATION_CONFIRMATION_PAGE_VIEWED);
    setHasAddedToAmplitude(true);
  }

  const startDeliveryCoupon = async () => {
    setScreenState((previousState) => ({
      ...previousState,
      loadingFlag: true
    }));
    sessionStorage.setItem('mobile', screenState.mobile);
    sendDataAnalytics(eventTypes.CONFIRM_ACTIVATION_BUTTON_CLICKED);
    const result = await xewardsCouponPost.postOrder(screenState.mobile, screenState.coupon);
    let redirectPath;
    if (result.status === 204) {
      redirectPath = `/transactions/coupons/${screenState.coupon}`;
    } else if (result.status === 409) {
      redirectPath = '/conflictCouponUsage/';
    } else if (result.status === 410) {
      redirectPath = `/transactions/coupons/${screenState.coupon}`;
    } else if (result.status === 500) {
      redirectPath = `/error/retryPostOrder/coupons/${screenState.coupon}`;
    } else {
      redirectPath = `/error/unknow/coupons/${screenState.coupon}`;
    }
    return setScreenState((previousState) => ({
      ...previousState,
      loadingFlag: false,
      couponData: result.data,
      shouldRedirect: true,
      redirectPath
    }));
  };

  const getTop = () => (
    <div className={classes.textDescriptionStyled}>
      <TextDescription
        content={'Está quase acabando!'}
      />
    </div>
  );

  const getCenter = () => {
    const { loadingFlag } = screenState;
    return (
      <Grid container justify='center' alignItems='center'>
        <Grid item lg={6} md={7} sm={8} xs={12}>
          <div className={classes.boxStyled}>
            <span className={classes.boxTitle}>
              NÚMERO CELULAR { screenState.couponProvider ? screenState.couponProvider.toUpperCase() : ''}
            </span>
            <span className={classes.boxMobileNumber}>
              {screenState.formattedMobile}
            </span>
            <Button
              id='modal-button-change-number'
              disabled={loadingFlag}
              onButtonClick={redirectToInput}
              textFace='Alterar Número'
              onlyText={true}
            />
          </div>
          <div className={classes.infoText}>
            <span className={classes.text}>
              O número está correto? Se sim, clique no botão <b>Finalizar</b>
            </span>
          </div>
        </Grid>
      </Grid>
    );
  };

  const getFooter = () => {
    const { loadingFlag } = screenState;
    return (
      <Button
        id='modal-button-activate-bonus'
        disabled={loadingFlag}
        loadingFlag={loadingFlag}
        onButtonClick={startDeliveryCoupon}
        textFace={loadingFlag ? 'Enviando dados' : 'Finalizar'}
      />
    );
  };
  if (screenState.shouldRedirect) {
    return (<Redirect to={screenState.redirectPath} />);
  }
  return (
    <DefaultContainer
      topContent={getTop()}
      centerContent={getCenter()}
      footerContent={getFooter()}
    />
  );
};

Confirmation.propTypes = {
  match: PropTypes.object.isRequired
};

export default Confirmation;
