import moment from 'moment';

export const initialState = {
  title: 'Carregando',
  unit: 'R$',
  value: '00',
  expirationDate: moment().format('MM/DD/YYYY'),
  couponCode: 'Carregando',
  phoneNumber: ''
};
