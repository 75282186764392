/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useImported } from 'react-imported-component';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import SVG from 'react-inlinesvg';
import muiTheme from 'components/theme/muiTheme';
import styles from './styles';
import moment from 'moment';

const useStyles = makeStyles(styles);

const TicketImage = ({ size, color }) => {
  if (size === 'largest') {
    const {
      imported: ticketLargest,
      loading
    } = useImported(() => import('img/couponlargest.svg'));
    return (
      !loading && (
        <SVG
          src={ticketLargest}
          preProcessor={color && ((code) => code.replace(/fill=".*?"/g, `fill="${color}"`))}
        />
      )
    );
  }
  const {
    imported: ticketSmall,
    loading
  } = useImported(() => import('img/couponsmall.svg'));
  return (
    !loading && (
      <SVG
        src={ticketSmall}
        preProcessor={color && ((code) => code.replace(/fill=".*?"/g, `fill="${color}"`))}
      />
    )
  );
};

TicketImage.propTypes = {
  size: PropTypes.string.isRequired,
  color: PropTypes.string
};

TicketImage.defaultProps = {
  color: ''
};

const IconImage = ({ icon }) => {
  if (icon === 'ticketBonusCelularIcon') {
    const {
      imported: ticketBonusCelularIcon,
      loading
    } = useImported(() => import('img/ticketBonusCelularIcon.svg'));
    return (
      !loading && (
        <SVG
          src={ticketBonusCelularIcon}
        />
      )
    );
  }
  const {
    imported: ticketBonusInternetIcon,
    loading
  } = useImported(() => import('img/ticketBonusInternetIcon.svg'));
  return (
    !loading && (
      <SVG
        src={ticketBonusInternetIcon}
      />
    )
  );
};

IconImage.propTypes = {
  icon: PropTypes.string.isRequired
};

const Ticket = ({
  size,
  title,
  value,
  unit,
  expirationDate,
  couponCode,
  color,
  logo
}) => {
  const classes = useStyles();
  let icon;
  let maskValue;
  if (unit === 'R$') {
    icon = 'ticketBonusCelularIcon';
    maskValue = `${value},00`;
  } else {
    icon = 'ticketBonusInternetIcon';
    maskValue = `${value}${unit}`;
  }
  const small = (size === 'small') ? '-small' : '';
  return (
    <>
      <div className={`${classes.ticket} ${small}`}>
        <TicketImage
          size={size}
          color={color}
        />
        <Grid container className={`content ${small}`}>
          <Grid item xs={12}>
            <Grid container className={`reward ${small}`}>
              <Grid item xs={9} className="information">
                <div className="title">
                  {
                    !logo
                      ? title
                      : (
                        <svg height="18">
                          <image href={logo} height="100%" />
                        </svg>
                      )
                  }
                </div>
                {
                  unit === 'R$'
                  && (
                    <div className={`dollar ${small}`}>
                      {unit}
                    </div>
                  )
                }
                <div className={`value ${small}`}>
                  {
                    value ? maskValue : <CircularProgress
                      size={24}
                      color="inherit"
                    />
                  }
                </div>
              </Grid>
              <Grid item xs={3} className="icon">
                <IconImage icon={icon} />
              </Grid>
            </Grid>
            <Grid container className={`couponData ${small}`}>
              <Grid item xs={5} className={`infoCoupon ${small}`}>
                <div className={`subTitle ${small}`}>
                  Cupom válido até
                </div>
                <div className={`subTitleValue ${small}`}>
                  {moment(new Date(expirationDate)).format('DD/MM/YYYY')}
                </div>
              </Grid>
              <Grid item xs={7} className={`infoCoupon ${small}`}>
                <div className={`subTitle ${small}`}>
                  Código do cupom
                </div>
                <div className={`subTitleValue ${small}`}>
                  {couponCode}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

Ticket.propTypes = {
  size: PropTypes.string,
  title: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  couponCode: PropTypes.string.isRequired,
  color: PropTypes.string,
  logo: PropTypes.string
};

Ticket.defaultProps = {
  size: 'largest',
  unit: null,
  title: 'Bônus celular',
  color: muiTheme.palette.primary.main,
  logo: ''
};

export default Ticket;
