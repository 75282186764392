const styles = {
  footer: {
    backgroundColor: '#ffffff',
    position: 'fixed',
    bottom: '0px',
    padding: '8px 0px 8px 0px',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    '& >.logoXewards': {
      width: '60px',
      height: '20px'
    }
  }
};

export default styles;
