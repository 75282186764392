/* eslint-disable */
import muiTheme from 'components/theme/muiTheme';

const styles = {
  ticketStyle: {
    margin: 'auto',
  },
  '& .contentTicket': {
    backgroundColor: '#f5f5f5',
    padding: '4.715127701%',
    marginTop: '-16px',
    width: '100%'
  },
  '& .info': {
    marginBottom: '16px',
    ['@media (min-height: 600px)']: {
      position: 'relative',
      top: '8px',
      marginBottom: '56px'
    },
  },
  '& .pageItens': {
    padding: '0 4.715127701%',
    '& >p': {
      padding: '0',
      margin: '0 0 1.76056338vh 0'
    },
    '& >.contentAsk': {
      position: 'absolute',
      color: '#959595',
      textTransform: 'uppercase',
      fontWeight: '500',
      fontSize: '0.875rem',
      width: '90%',
      bottom: '21.126760563vh',
      cursor: 'pointer',
      '& >.icon': {
        marginRight: '5px',
        fontSize: '17px',
        lineHeight: '1.3'
      }
    },
    '& >.contentButton': {
      position: 'absolute',
      bottom: '9.85915493vh',
      width: '90%'
    }
  },
  img: {
    paddingBottom: '16px'
  },
  text: {
    padding: '0px 0px 16px 0px',
    fontSize: '14px',
    lineHeight: '1.2'
  },
  btnStyle: {
    paddingTop: '32px'
  },
  page: {
    padding: '0px',
    display: 'flex',
    ['@media (min-width: 600px)']: {
      padding: '36px'
    },
    ['@media (min-width: 1200px)']: {
      padding: '36px'
    }
  },
  contentTicket: {
    backgroundColor: '#f5f5f5',
    padding: '4.715127701%',
    margin: '-16px 0 0 -16px',
    width: 'calc(100% + 32px)'
  },
  info: {
    height: '26vh',
    ['@media (min-width: 600px)']: {
      height: '16vh'
    }
  },
  formattedPaper: {
    padding: '16px 16px 16px 16px',
    display: 'block',
    maxHeight: '571px',
    boxShadow: 'none',
    ['@media (min-width: 600px)']: {
      padding: '16px 16px 48px 16px',
      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
    }
  },
  titleColor: {
    color: muiTheme.palette.primary.main
  },
  titleStyle: {
    textAlign: 'center',
    color: muiTheme.palette.primary.main,
    padding: '16px 0px 8px 0px'
  },
  subtitleStyle: {
    textAlign: 'center',
    lineHeight: 1
  },
  descriptionStyle: {
    textAlign: 'center',
    paddingBottom: '16px',
    fontSize: '16px'
  },
  list: {
    padding: '16px 0'
  },
  listItem: {
    padding: '8px',
    color: '#666666',
  },
  listItemPrimary: {
    textAlign: 'left',
    fontWeight: '300',
    fontSize: '12px'
  },
  listItemSecondary: {
    textAlign: 'right',
    fontWeight: '600',
    fontSize: '14px'
  }
};

export default styles;
