const styles = {
  containerButton: {
    display: 'block',
    justifyContent: 'center',
    '& >.button': {
      textAlign: 'center',
      height: '44px',
      width: '100%'
    },
    position: 'relative'
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
};

export default styles;
