import AppConfig from '../../lib/config';
import makeRequest from '../../utils/request';

const token = `bearer ${AppConfig.getConfig('XEWARDS_DELIVERY_COUPON_TOKEN')}`;

const getCoupon = (coupon) => {
  const url = `${AppConfig.getConfig('XEWARDS_DELIVERY_COUPON_URL')}/coupons/${coupon}`;
  return makeRequest(url, token, 'get');
};

export default getCoupon;
