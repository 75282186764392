const styles = {
  textDescriptionStyled: {
    padding: '8px 0px'
  },
  boxStyled: {
    border: '1px solid #E5E5E5',
    borderRadius: '8px',
    margin: '0px 8px 24px 8px',
    paddingBottom: '24px',
    display: 'grid',
    alignItems: 'center'
  },
  boxTitle: {
    fontSize: '12px',
    color: '#666666',
    padding: '16px 16px 8px 16px'
  },
  boxMobileNumber: {
    color: '#4C4C4C',
    fontSize: '28px',
    paddingBottom: '16px',
    fontWeight: '500'
  },
  text: {
    marginTop: 1000,
    color: '#000'
  },
  infoText: {
    margin: '60px 20px'
  }
};

export default styles;
