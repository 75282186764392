import React, { useState } from 'react';
import Button from '../../atoms/Button/button';
import Image from '../../atoms/Image/image';
import Title from '../../atoms/Title/title';
import TextDescription from '../../atoms/TextDescription/textDescription';
import DefaultContainer from '../../organisms/DefaultContainer/default-container';
import imageRescued from '../../../img/imgCouponRescued.svg';
import AppConfig from '../../../lib/config';
import * as AnalyticsManager from '../../../analytics/analyticsManager';
import * as eventTypes from '../../../analytics/eventTypes';

const ConflictCouponUsage = () => {
  const pageRedirectUrl = AppConfig.getConfig('CONFLICT_URL_COUPON_USAGE');
  const [hasAddedToAmplitude, setHasAddedToAmplitude] = useState(false);

  const sendDataAnalytics = (eventType) => {
    const dataAnalytics = AnalyticsManager.getDataFromSessionStorage();

    AnalyticsManager.saveDataAnalytics(
      dataAnalytics,
      eventType
    );
  };

  if (!hasAddedToAmplitude) {
    sendDataAnalytics(eventTypes.COUPON_CONFLICT_USAGE_PAGE_VIEWED);
    setHasAddedToAmplitude(true);
  }

  const [screenState, setScreenState] = useState({
    shouldRedirectToHelpPage: false
  });

  const redirectToHelpPage = () => {
    setScreenState((previousState) => ({
      ...previousState,
      shouldRedirectToHelpPage: true
    }));
    sendDataAnalytics(eventTypes.COUPON_CONFLICT_USAGE_BUTTON_CLICKED);
  };

  const getTop = () => <Image src={imageRescued} color={null} />;

  const getBottom = () => (
    <>
      <Title title={'Voucher já resgatado!'} />
      <TextDescription content={'Este voucher já foi ativado em um outro número.'} />
      <TextDescription>
        Caso você não tenha realizado essa ativação, entre em contato com o nosso atendimento.
      </TextDescription>
    </>
  );

  const getFooter = () => (
    <Button
      id='conflict-button-usage'
      onButtonClick={redirectToHelpPage}
      textFace='Acessar central de atendimento'
    />
  );

  if (screenState.shouldRedirectToHelpPage) {
    window.open(pageRedirectUrl);
  }

  return (
    <DefaultContainer
      topContent={getTop()}
      bottomContent={getBottom()}
      footerContent={getFooter()}
    />
  );
};

export default ConflictCouponUsage;
