import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import styles from './styles';
import Image from '../../atoms/Image/image';
import DefaultContainer from '../../organisms/DefaultContainer/default-container';
import Title from '../../atoms/Title/title';
import TextDescription from '../../atoms/TextDescription/textDescription';
import imageSuccess from '../../../img/imgCupomSucess.svg';

const useStyles = makeStyles(styles);

const Success = () => {
  const classes = useStyles();
  const title = 'Agora é só esperar.';
  const description = 'Estamos verificando se está tudo certo com o número informado.'
    + '<br/>Caso esteja tudo certo, o seu voucher será ativado em até <b>48 horas</b>.';

  const getTop = () => (
    <Grid container direction='column' justify='center' alignItems='center'>
      <Grid item lg={4} md={4} sm={10} xs={10} style={styles.img}>
        <Image src={imageSuccess} />
      </Grid>
    </Grid>
  );

  const getBottom = () => (
    <>
      <Title title={title} />
      <TextDescription content={description} />
      <Link to='status' className={classes.linkStyle}>
        ACOMPANHAR ATIVAÇÃO DO BÔNUS
      </Link>
    </>
  );

  return (
    <DefaultContainer
      topContent={getTop()}
      bottomContent={getBottom()}
    />
  );
};

export default Success;
