import amplitude from 'amplitude-js';
import AppConfig from '../lib/config';
import version from '../../package.json';

export const init = () => {
  amplitude.getInstance().init(AppConfig.getConfig('AMPLITUDE_TOKEN'));
};

const sendEventProperties = (eventType, eventProperties = {}) => {
  amplitude
    .getInstance()
    .logEvent(
      eventType,
      eventProperties
    );
};

const sendIdentifyUser = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

const sendDataAnalytics = (
  eventType,
  eventProperty = {}
) => {
  sendIdentifyUser(eventProperty.userId);
  sendEventProperties(
    eventType,
    {
      client: 'xewards-delivery-coupon-ui',
      version: version.version,
      ...eventProperty
    }
  );
};

export const saveDataAnalytics = (data, typeEvent, extraData = {}) => {
  const analyticsData = { ...data, ...extraData };
  sendDataAnalytics(typeEvent, analyticsData);
};

export const getDataFromSessionStorage = () => (sessionStorage.getItem('dataAnalytics')
  ? JSON.parse(sessionStorage.getItem('dataAnalytics')) : {});

export const setDataToSessionStorage = (data) => {
  const dataAnalytics = {
    userId: data._embedded.consumer.id,
    prizeName: data.bucket.name,
    allianceName: data._embedded.alliance.name,
    prizeType: data.bucket._embedded.asset.ticket.unit,
    value: data.bucket.quantify
  };

  sessionStorage.setItem('dataAnalytics', JSON.stringify(dataAnalytics));
  return dataAnalytics;
};
