import muiTheme from '../../theme/muiTheme';

const styles = {
  page: {
    padding: '0px',
    display: 'flex',
    height: '100%',
    ['@media (min-width: 600px)']: { // eslint-disable-line no-useless-computed-key
      padding: '36px'
    }
  },
  formattedPaper: {
    padding: '0px',
    display: 'block',
    boxShadow: 'none',
    height: '100%',
    ['@media (min-width: 600px)']: { // eslint-disable-line no-useless-computed-key
      padding: '0px',
      boxShadow: `
        0px 1px 3px 0px rgba(0,0,0,0.2),
        0px 1px 1px 0px rgba(0,0,0,0.14), 
        0px 2px 1px -1px rgba(0,0,0,0.12)`,
      height: 'auto'
    }
  },
  btnStyle: {
    position: 'fixed',
    bottom: '32px',
    width: '100%',
    ['@media (min-width: 600px)']: { // eslint-disable-line no-useless-computed-key
      position: 'relative',
      bottom: '0px'
    }
  },
  topContent: {
    maxHeight: '18.19%'
  },
  centerContent: {
    maxHeight: '45.45%'
  },
  bottomContent: {
    overflow: 'auto',
    ['@media (min-width: 600px)']: { // eslint-disable-line no-useless-computed-key
      overflow: 'none'
    }
  },
  footerContent: {
    height: 'auto',
    padding: '0px 16px 16px 16px'
  },
  titleColor: {
    color: muiTheme.palette.primary.main
  },
  titleStyle: {
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '20px',
    color: muiTheme.palette.primary.main
  },
  subtitleStyle: {
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: 1
  },
  descriptionStyle: {
    textAlign: 'center',
    paddingBottom: '16px',
    fontSize: '16px'
  }
};

export default styles;
