import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Markup } from 'interweave';
import useDecode from '../../../utils/useDecode';
import styles from './styles';

const useStyles = makeStyles(styles);

const TextDescription = ({ content, children }) => {
  const classes = useStyles();

  const data = useDecode(content?.length ? content : children);

  return (
    <div data-testid='descriptionText' className={classes.descriptionFormatted}>
      <Markup className={classes.descriptionStyle} content={data} />
    </div>
  );
};

TextDescription.propTypes = {
  content: PropTypes.string,
  children: PropTypes.string
};

export default TextDescription;
