const styles = {
  formattedImage: {
    padding: '16px 16px 0px 16px'
  },
  imageStyle: {
    width: '100%',
    height: 'auto'
  }
};

export default styles;
