import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import styles from './styles';

const useStyles = makeStyles(styles);

const CssTextField = withStyles(styles)(TextField);

const TextFields = ({
  id,
  label,
  placeholder,
  hasError,
  getTextValue,
  isRequired,
  isDisabled,
  valueText,
  textType,
  helperText,
  onBlurTextField,
  mask
}) => {
  const [values, setValues] = React.useState({
    name: valueText
  });
  const onTextFieldChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
    getTextValue(event.target.value);
  };
  const classes = useStyles();

  return (
    <div className={classes.containerTextField}>
      <CssTextField
        id={`${id}-text-field`}
        label={label}
        placeholder={placeholder}
        error={hasError}
        onChange={onTextFieldChange('name')}
        onBlur={onBlurTextField}
        required={isRequired}
        disabled={isDisabled}
        type={textType}
        helperText={helperText}
        className='textField'
        value={values.name}
        margin='none'
        variant='outlined'
        inputProps={{
          maxLength: 15
        }}
        onInput={mask}
      />
    </div>
  );
};

TextFields.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
  valueText: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  textType: PropTypes.string,
  helperText: PropTypes.string,
  getTextValue: PropTypes.func.isRequired,
  onBlurTextField: PropTypes.func,
  mask: PropTypes.func
};

TextFields.defaultProps = {
  hasError: false,
  valueText: '',
  isRequired: false,
  isDisabled: false,
  helperText: '',
  textType: 'tel',
  placeholder: '',
  onBlurTextField: () => { },
  mask: () => { }
};

export default TextFields;
