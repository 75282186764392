import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextDescription from '../TextDescription/textDescription';
import styles from './styles';

const useStyles = makeStyles(styles);

const Title = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleFormatted}>
      <Typography className={classes.titleStyle}>{title}</Typography>
      <TextDescription content={subtitle} />
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default Title;
