import React from 'react';
import Proptypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SVG from 'react-inlinesvg';
import Grid from '@material-ui/core/Grid';
import muiTheme from '../../theme/muiTheme';
import styles from './styles';

const useStyles = makeStyles(styles);

const Image = ({ color, src }) => {
  const classes = useStyles();
  return (
    <Grid container direction='row' justify='center' alignItems='center'>
      <Grid item lg={8} md={8} sm={8} xs={8} className={classes.formattedImage}>
        <SVG
          src={src}
          className={classes.imageStyle}
          preProcessor={color && ((code) => code.replace(/fill="(?!(#f7b690)).*?"/gi, `fill="${color}"`))}
        />
      </Grid>
    </Grid>
  );
};

Image.propTypes = {
  color: Proptypes.string,
  src: Proptypes.string.isRequired
};

Image.defaultProps = {
  color: muiTheme.palette.primary.main
};

export default Image;
