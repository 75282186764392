import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Proptypes from 'prop-types';
import { Grid, MobileStepper, Fab } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Image from '../../atoms/Image/image';
import Title from '../../atoms/Title/title';
import TextDescription from '../../atoms/TextDescription/textDescription';
import styles from './styles';

const useStyles = makeStyles(styles);

const TextMobileStepper = ({ tutorialSteps, getActiveStep: exportActiveStep }) => {
  const theme = useTheme();
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    exportActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    exportActiveStep(activeStep - 1);
  };

  return (
    <Grid container direction='column' justify='center' alignItems='center'>
      <Image src={tutorialSteps[activeStep].image} style={{ padding: '16px 0px 16px 0px' }} />
      <Title title={tutorialSteps[activeStep].title} />
      <span className={classes.wrapperDescription}>
        <TextDescription content={tutorialSteps[activeStep].description} />
      </span>
      <Fab
        size='small'
        className={classes.btnNext}
        onClick={handleNext}
        disabled={activeStep === maxSteps - 1}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </Fab>
      <Fab size='small' className={classes.btnPrev} onClick={handleBack} disabled={activeStep === 0}>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </Fab>
      <div>
        <MobileStepper
          steps={maxSteps}
          position='static'
          variant='dots'
          activeStep={activeStep}
          className={classes.navStyle}
        />
      </div>
    </Grid>
  );
};

TextMobileStepper.propTypes = {
  tutorialSteps: Proptypes.array,
  getActiveStep: Proptypes.func
};

export default TextMobileStepper;
