import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import Ticket from '../../molecules/Ticket/ticket';
import TextField from '../../atoms/TextField/textField';
import TextDescription from '../../atoms/TextDescription/textDescription';
import Button from '../../atoms/Button/button';
import Modal from '../../molecules/Modal/modal';
import getCoupon from '../../../clients/xewardsDeliveryCoupon/get';
import couponClientPost from '../../../clients/xewardsDeliveryCoupon/post';
import * as AnalyticsManager from '../../../analytics/analyticsManager';
import * as eventTypes from '../../../analytics/eventTypes';
import styles from './styles';

const useStyles = makeStyles(styles);

const getPhoneNumberMask = (eventValue) => {
  const phoneMaskRegex = /^\(?(\d{1,2})\)?\s?(\d{1,5})?-?(\d{1,4})?/g;
  const mobile = eventValue.replace(/\D/g, '')
    .replace(phoneMaskRegex, '($1) $2-$3')
    .replace(/\D*$/g, '');
  return mobile;
};

const PhoneNumber = ({ match }) => {
  const [loadingMobileValidation, setLoadingMobileValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [couponData, setCouponData] = useState({});
  const [consumerData, setConsumerData] = useState({});
  const [mobileValue, setMobileValue] = useState('');
  const [enableNextStep, setEnableNextStep] = useState(false);
  const [usedPhoneNumber, setUsedPhoneNumber] = useState([]);
  const [errorNumber, setErrorNumber] = useState({ hasError: false, helperText: '' });
  const [redirectToInvalidNumber, setRedirectToInvalidNumber] = useState(false);
  const [redirectToConfirmation, setRedirectToConfirmation] = useState(false);
  const [errorCoupon, setErrorCoupon] = useState();
  const [hasAddedToAmplitude, setHasAddedToAmplitude] = useState(false);
  const classes = useStyles();

  const removeMask = (value) => value.replace(/[&()-/\s//\D+]/g, '');
  const cleanMobileValue = removeMask(mobileValue);

  const errorDescription = (helperText) => setErrorNumber({ hasError: true, helperText });

  const onBlurInputMobile = () => ((removeMask(mobileValue).length < 11)
    ? errorDescription('Informe um número de celular válido')
    : setErrorNumber({}));

  const handleEnableNextStep = (value) => {
    if (value.length >= 15) {
      setEnableNextStep(false);
    } else {
      setEnableNextStep(true);
      setErrorNumber({});
    }
  };

  const handleChangeMobileField = (value) => {
    setMobileValue(value);
  };

  const searchingNumberPhoneOnList = (number) => usedPhoneNumber.indexOf(number) !== -1 && true;

  const validateMobileEligibility = async () => {
    const msisdn = `55${cleanMobileValue}`;
    sessionStorage.setItem('mobile', msisdn);
    sessionStorage.setItem('bucket', couponData.bucket);
    const { status } = await couponClientPost.postConsumersValidate(
      msisdn,
      couponData.alliance,
      couponData.bucket
    );

    if (status === 200) {
      return setRedirectToConfirmation(true);
    }
    if (status === 400) {
      setLoadingMobileValidation(false);
      setEnableNextStep(true);
      return errorDescription(`Informe um número da operadora ${couponData.alliance.toUpperCase()}`);
    }
    if (status === 404) {
      return setRedirectToInvalidNumber(true);
    }
    setErrorCoupon(`/error/retryPhoneNumberValidate/coupons/${match.params.couponCode}`);
  };

  const handleClickButton = () => {
    if (searchingNumberPhoneOnList(cleanMobileValue)) {
      errorDescription('Número já utilizado');
      setEnableNextStep(true);
    } else {
      setUsedPhoneNumber([...usedPhoneNumber, cleanMobileValue]);
      setLoadingMobileValidation(true);
      validateMobileEligibility();
    }

    const dataAnalytics = AnalyticsManager.getDataFromSessionStorage();
    AnalyticsManager
      .saveDataAnalytics(dataAnalytics, eventTypes.AUTHORIZE_PHONE_NUMBER_BUTTON_CLICKED);
  };

  useEffect(() => {
    if (removeMask(mobileValue).length < 11) {
      errorDescription('Informe um número de celular válido');
    } else {
      setErrorNumber({});
    }
    handleEnableNextStep(mobileValue);
  }, [mobileValue]);

  useEffect(() => {
    const saveDataAnalytics = (data) => {
      if (!hasAddedToAmplitude) {
        const dataAnalytics = AnalyticsManager.setDataToSessionStorage(data);
        AnalyticsManager.saveDataAnalytics(dataAnalytics, eventTypes.PHONE_NUMBER_PAGE_VIEWED);
        setHasAddedToAmplitude(true);
      }
    };
    const loadContext = async () => {
      const { status, data } = await getCoupon(match.params.couponCode);
      if (status === 200) {
        const { asset } = data.bucket._embedded;
        saveDataAnalytics(data);
        setCouponData((previousState) => ({
            ...previousState,
            title: asset.ticket.title,
            unit: asset.ticket.unit,
            value: asset.ticket.value.toString(),
            bucket: data.bucket.name,
            expirationDate: data.expirationDate,
            couponCode: data.coupon,
            alliance: data._embedded.alliance.name,
            logo: asset.ticket.logo
          }));
        setConsumerData((previousState) => ({
            ...previousState,
            mobile: data._embedded.consumer.mobile,
            name: data._embedded.consumer.name
          }));
        setIsLoading(false);
      } else {
        setErrorCoupon(`/error/retryPhoneNumberValidate/coupons/${match.params.couponCode}`);
        setIsLoading(false);
      }
    };
    loadContext();
  }, [match.params.couponCode, hasAddedToAmplitude]);

  if (isLoading) {
    return (
      <div className={classes.page}>
        <Grid container justify='center'>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Paper className={classes.formattedPaper}>
              <Grid container justify='center' className={classes.bonusDetail}>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                  <CircularProgress color='secondary' />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
  if (redirectToInvalidNumber) {
    return (<Redirect to={`/invalidNumber/coupons/${match.params.couponCode}`} />);
  }
  if (redirectToConfirmation) {
    return (<Redirect to={`/confirmation/coupons/${couponData.couponCode}`} />);
  }
  if (errorCoupon) {
    return (<Redirect to={errorCoupon} />);
  }

  const getCenter = () => {
    const alliance = couponData ? couponData.alliance.toUpperCase() : '';
    const description = `${consumerData.name ? `<b>${consumerData.name}</b>, digite ` : 'Insira '
       }o número que deseja ativar o seu voucher:`;
    const label = `Número celular ${alliance}`;
    return (
      <div>
        <div className={classes.ticketStyle}>
          <Grid container justify='center' className={classes.ticketGrid}>
            <Grid item lg={6} md={6} sm={8} xs={12}>
              <Ticket
                size='small'
                title={couponData.title}
                unit={couponData.unit}
                value={couponData.value}
                expirationDate={couponData.expirationDate}
                couponCode={couponData.couponCode}
                logo={couponData.logo}
              />
            </Grid>
          </Grid>
        </div>
        <div>
          <div className={classes.box}>
            <TextDescription content={description} />
            <TextField
              id='phone-number-textField'
              getTextValue={handleChangeMobileField}
              label={label}
              placeholder='(00) 00000-0000'
              hasError={errorNumber.hasError}
              helperText={errorNumber.helperText}
              onBlurTextField={onBlurInputMobile}
              mask={(e) => {
                e.target.value = getPhoneNumberMask(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const getButtons = () => (
    <div className={classes.buttons}>
      <Modal
        origin='phoneNumber'
        disableActivateBonus={false}
        onlyText={true}
        textButton='Para rever o tutorial, clique aqui!'
      />
      <Button
        id='phone-number-button'
        textFace='Continuar ativação'
        loadingFlag={loadingMobileValidation}
        onButtonClick={handleClickButton}
        disabled={enableNextStep}
      />
    </div>
    );

  return (
    <span className={classes.centered}>
      <div className={classes.wrapper}>
        {getCenter()}
        {getButtons()}
      </div>
    </span>
  );
};

PhoneNumber.propTypes = {
  match: PropTypes.object.isRequired
};

export default PhoneNumber;
