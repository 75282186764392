import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './styles';

const useStyles = makeStyles(styles);
const ContainedButtons = ({
  onButtonClick,
  id,
  textFace,
  color,
  disabled,
  loadingFlag,
  onlyText
}) => {
  const classes = useStyles();
  const spinner = (
    <CircularProgress
      size={24}
      className={classes.spinner}
    />
);
  return (
    <div className={classes.containerButton}>
      <Button
        id={`${id}-container-button`}
        variant={onlyText ? 'text' : 'contained'}
        color={color}
        disabled={disabled}
        className='button'
        onClick={onButtonClick}
      >
        {textFace}
      </Button>
      { loadingFlag && spinner }
    </div>
  );
};
ContainedButtons.propTypes = {
  color: PropTypes.string,
  textFace: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loadingFlag: PropTypes.bool,
  onlyText: PropTypes.bool
};
ContainedButtons.defaultProps = {
  color: 'secondary',
  disabled: false,
  loadingFlag: false,
  onlyText: false
};
export default ContainedButtons;
