import React from 'react';
import Image from '../../atoms/Image/image';
import Title from '../../atoms/Title/title';
import TextDescription from '../../atoms/TextDescription/textDescription';
import DefaultContainer from '../../organisms/DefaultContainer/default-container';

import imageNumberError from '../../../img/imgNumberError.svg';

const NotFound = () => {
  const getCenter = () => <Image src={imageNumberError} color={null} />;

  const getBottom = () => (
    <>
      <Title title={'Ops, página não encontrada!'} />
      <TextDescription
        content={'A página que você está buscando não foi encontrada.'
        + '<br><br> Verifique o endereço digitado e tente novamente.'}
      />
    </>
  );

  return (
    <DefaultContainer
      centerContent={getCenter()}
      bottomContent={getBottom()}
    />
  );
};

export default NotFound;
