import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './components/pages/Home/home';
import Confirmation from './components/pages/Confirmation/confirmation';
import NotFound from './components/pages/NotFound/notFound';
import InvalidNumber from './components/pages/InvalidNumber/invalidNumber';
import ServiceNotAvailable from './components/pages/ServiceNotAvailable/serviceNotAvailable';
import PhoneNumber from './components/pages/PhoneNumber/phoneNumber';
import Success from './components/pages/Success/success';
import Status from './components/pages/Status/status';
import ConflictCouponUsage from './components/pages/ConflictCouponUsage/conflictCouponUsage';

const Router = () => (
  <Switch>
    <Route path='/coupons/:couponCode' component={Home} />
    <Route path='/confirmation/coupons/:couponCode' component={Confirmation} />
    <Route path='/invalidNumber/coupons/:couponCode' component={InvalidNumber} />
    <Route path='/conflict/:couponCode' component={InvalidNumber} />
    <Route path='/phonenumber/coupons/:couponCode' component={PhoneNumber} />
    <Route path='/transactions/coupons/:couponCode' component={Status} />
    <Route path='/conflictCouponUsage' component={ConflictCouponUsage} />
    <Route path='/error/:sourceErrorEnum/coupons/:couponCode' component={ServiceNotAvailable} />
    <Route path='/coupons/:couponCode/success' component={Success} />
    <Route path='/status/:couponCode' component={Status} />
    <Route path='*' component={NotFound} />
  </Switch>
);

export default Router;
