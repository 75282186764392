import AppConfig from '../../lib/config';
import makeRequest from '../../utils/request';

const token = `bearer ${AppConfig.getConfig('XEWARDS_DELIVERY_COUPON_TOKEN')}`;

const postOrder = (mobile, coupon) => {
  const url = `${AppConfig.getConfig('XEWARDS_DELIVERY_COUPON_URL')}/coupons/${coupon}/order`;
  const body = { mobile };
  return makeRequest(url, token, 'post', body);
};

const postConsumersValidate = (mobile, alliance, bucket) => {
  const body = { mobile, alliance, bucket };
  const url = `${AppConfig.getConfig('XEWARDS_DELIVERY_COUPON_URL')}/consumers/validate`;
  return makeRequest(url, token, 'post', body);
};

export default { postOrder, postConsumersValidate };
