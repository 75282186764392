import muiTheme from '../../theme/muiTheme';

const styles = {
  wrapperDescription: {
    '@media (min-width: 800px)': {
      padding: '0 25px'
    }
  },
  formattedComponent: {
    alignItems: 'center',
    width: '100%',
    display: 'block',
    backgroundColor: 'transparent',
    padding: '0px'
  },
  paperStyle: {
    display: 'block',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: 'white',
    padding: '8px 0px 16px 0px'
  },
  titleStyle: {
    textAlign: 'center',
    color: muiTheme.palette.primary.main,
    paddingBottom: '16px',
    lineHeight: '1.2'
  },
  textStyle: {
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '1.2'
  },
  stepperStyle: {
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  tipsImg: {
    height: '20vh',
    display: 'flex',
    alignItems: 'flex-end',
    margin: '0 auto'
  },
  tipsTxt: {
    textAlign: 'center',
    padding: '0 54px'
  },
  tipsNavigation: {
    height: '5vh'
  },
  imgStyle: {
    height: '120px'
  },
  navStyle: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '17%',
    left: '50%',
    right: '50%'
  },
  btnPrev: {
    position: 'absolute',
    top: '76%',
    left: '16px',
    color: muiTheme.palette.primary.main,
    display: 'flex',
    zIndex: 5
  },
  btnNext: {
    position: 'absolute',
    top: '76%',
    right: '16px',
    color: muiTheme.palette.primary.main,
    zIndex: 5
  }
};

export default styles;
