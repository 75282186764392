export const COUPON_REDEEMED_PAGE_VIEWED = 'Coupon Redeemed Page Viewed';
export const SHOW_ACTIVATION_RULES_BUTTON_CLICKED = 'Show Activation Rules Button Clicked';
export const TIPS_VIEWED = 'Tips Viewed';
export const TIPS_CLOSE_BUTTON_CLICKED = 'Tips Close Button Clicked';
export const TIPS_SKIP_BUTTON_CLICKED = 'Tips Skip Button Clicked';

export const PHONE_NUMBER_PAGE_VIEWED = 'Phone Number Page Viewed';
export const AUTHORIZE_PHONE_NUMBER_BUTTON_CLICKED = 'Authorize Phone Number Button Clicked';
export const ERROR_MOBILE_NUMBER_MESSAGE_VIEWED = 'Invalid Mobile Number Page Viewed';
export const TIPS_PHONE_NUMBER_BUTTON_CLICKED = 'Tips on Phone Number Button Clicked';

export const COUPON_ACTIVATION_CONFIRMATION_PAGE_VIEWED = 'Coupon Activation Confirmation Page Viewed';
export const CHANGE_NUMBER_BUTTON_CLICKED = 'Change Number  Button Clicked';
export const CONFIRM_ACTIVATION_BUTTON_CLICKED = 'Confirm Activation Button Clicked';

export const END_ACTIVATION_PAGE_VIEWED = 'End Activation Page Viewed';
export const END_ACTIVATION_BUTTON_CLICKED = 'End Activation Show Status Button Clicked';

export const INVALID_MOBILE_NUMBER_PAGE_VIEWED = 'Invalid Mobile Number Page Viewed';
export const INFORM_NEW_MOBILE_NUMBER_BUTTON_CLICKED = 'Inform New Mobile Number Button Clicked';

export const COUPON_STATUS_PAGE_VIEWED = 'Coupon Status Page Viewed';

export const SERVICE_UNAVAILABLE_PAGE_VIEWED = 'Service Unavailable Page Viewed';
export const SERVICE_UNAVAILABLE_RETRY_BUTTON_CLICKED = 'Service Unavailable Retry Button Clicked';

export const COUPON_CONFLICT_USAGE_PAGE_VIEWED = 'Coupon Conflict Usage Page Viewed';
export const COUPON_CONFLICT_USAGE_BUTTON_CLICKED = 'Coupon Conflict Usage Button Clicked';
