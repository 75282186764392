import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import styles from './styles';

const useStyles = makeStyles(styles);
const DefaultContainer = ({
  topContent,
  centerContent,
  bottomContent,
  footerContent
}) => {
  const classes = useStyles();
  return (
    <div className={classes.page}>
      <Grid container justify='center'>
        <Grid item lg={6} md={6} sm={8} xs={12}>
          <Paper className={classes.formattedPaper}>
            <Grid container justify='center'>
              <Grid item lg={8} md={8} sm={8} xs={12}>
                <div className={classes.topContent}>
                  {topContent}
                </div>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={classes.centerContent}>
                  {centerContent}
                </div>
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={12}>
                <div className={classes.bottomContent}>
                  {bottomContent}
                </div>
                <div className={classes.btnStyle}>
                  <div className={classes.footerContent}>
                    {footerContent}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

DefaultContainer.propTypes = {
  topContent: PropTypes.element,
  centerContent: PropTypes.element,
  bottomContent: PropTypes.element,
  footerContent: PropTypes.element
};
DefaultContainer.defaultProps = {
  topContent: null,
  centerContent: null,
  bottomContent: null,
  footerContent: null
};
export default DefaultContainer;
