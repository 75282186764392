const decodeEntities = (function() {
  const cache = {};
  let text;
  const e = document.createElement('span');
  return function(html) {
    if (!html) {
      return '';
    }
    return html.replace(/([&][^&; ]+[;])/g, (entity) => {
      text = cache[entity];
      if (!text) {
        e.innerHTML = entity;
        if (e.childNodes[0]) {
          text = e.childNodes[0].nodeValue;
          cache[entity] = e.childNodes[0].nodeValue;
        } else {
          text = '';
        }
      }
      return text;
    });
  };
})();

export default decodeEntities;
