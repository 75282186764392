const applyMobileMask = (mobile) => {
  const regex = /^55(\d{2})(\d{5})(\d{4})$/gm;
  if (!regex.test(mobile)) {
    return;
  }
  const str = mobile;
  const subst = '($1) $2-$3';
  return str.replace(regex, subst);
};
export default { applyMobileMask };
