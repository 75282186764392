const styles = {
  button: {
    ['@media (min-width: 800px)']: { // eslint-disable-line no-useless-computed-key
      padding: '0 40px'
    }
  },
  page: {
    padding: '16px',
    display: 'flex',
    margin: '0 auto',
    justifyContent: 'center',
    ['@media (min-width: 600px)']: { // eslint-disable-line no-useless-computed-key
      padding: '36px'
    }
  },
  ticketStyled: {
    margin: 'auto',
    padding: '0px 16px 16px'
  },
  bonusDetail: {
    paddingTop: '16px',
    paddingBottom: '16px'
  },
  info: {
    height: '20vh',
    paddingBottom: '16px',
    ['@media (min-width: 600px)']: { // eslint-disable-line no-useless-computed-key
      height: '16vh'
    }
  },
  btnHome: {
    paddingTop: '16px'
  },
  formattedPaper: {
    padding: '16px 16px 16px 16px',
    display: 'block',
    maxHeight: '571px',
    boxShadow: 'none',
    ['@media (min-width: 600px)']: { // eslint-disable-line no-useless-computed-key
      padding: '16px 16px 48px 16px',
      // eslint-disable-next-line
      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
    }
  }
};

export default styles;
