import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Title from '../../atoms/Title/title';
import TextDescription from '../../atoms/TextDescription/textDescription';
import Image from '../../atoms/Image/image';
import DefaultContainer from '../../organisms/DefaultContainer/default-container';
import Button from '../../atoms/Button/button';
import imageError from '../../../img/imgError.svg';
import getCoupons from '../../../clients/xewardsDeliveryCoupon/get';
import postXewardsDeliveryCoupon from '../../../clients/xewardsDeliveryCoupon/post';
import * as AnalyticsManager from '../../../analytics/analyticsManager';
import * as eventTypes from '../../../analytics/eventTypes';
import AppConfig from '../../../lib/config';

const title = 'Ops, serviço indisponível!';
const description = `
  O problema não é com você. Não conseguimos conectar ao nosso servidor
  ou não identificamos as configurações do voucher.
  <br><br> Você pode realizar uma nova tentativa clicando no botão abaixo.'
`;

const descriptionWithoutAssets = `
  Isso não é muito normal de acontecer. Nossos servidores ainda não estão prontos.
  <br><br> Por favor, retorne dentro de alguns instantes para ativar seu bônus.
`;

const defaultMaxRetries = 3;
const maxRetries = parseInt(AppConfig.getConfig('MAX_RETRIES'), 10) || defaultMaxRetries;
const retryStrategy = {
  retryGetCoupons: {
    execute: async (coupon) => {
      const { status } = await getCoupons(coupon);
      if (status === 200) {
        return { success: true, path: `/coupons/${coupon}` };
      }
      return { success: false };
    }
  },
  retryPostOrder: {
    execute: async (coupon) => {
      const mobile = sessionStorage.getItem('mobile');
      const { status } = await postXewardsDeliveryCoupon.postOrder(mobile, coupon);
      if (status === 204) {
        return { success: true, path: `/transactions/coupons/${coupon}` };
      }
      return { success: false };
    }
  },
  retryPhoneNumberValidate: {
    execute: async (coupon) => {
      const alliance = sessionStorage.getItem('allianceName');
      const mobile = sessionStorage.getItem('mobile');
      const bucket = sessionStorage.getItem('bucket');
      if (mobile) {
        const { status } = await postXewardsDeliveryCoupon
          .postConsumersValidate(mobile, alliance, bucket);
        if (status === 200) {
          return { success: true, path: `/confirmation/coupons/${coupon}` };
        }
        if (status !== 500) {
          sessionStorage.removeItem('mobile');
          return { success: true, path: `/phonenumber/coupons/${coupon}` };
        }
        return { success: false };
      }
      const { status } = await getCoupons(coupon);
      if (status === 200) {
        return { success: true, path: `/phonenumber/coupons/${coupon}` };
      }
      return { success: false };
    }
  },
  unknow: {
    execute: async (coupon) => ({ success: true, path: `/coupons/${coupon}` })
  }
};

const executeStrategy = async (name, couponCode) => {
  if (retryStrategy[name]) {
    return retryStrategy[name].execute(couponCode);
  }
  return retryStrategy.unknown.execute(couponCode);
};
const ServiceNotAvailable = ({ match }) => {
  const [retryCount, setRetryCount] = useState(0);
  const [redirectPath, setRedirectPath] = useState(false);
  const { couponCode } = match.params;
  const [hasAddedToAmplitude, setHasAddedToAmplitude] = useState(false);

  const sendDataAnalytics = (eventType) => {
    const dataAnalytics = AnalyticsManager.getDataFromSessionStorage();

    AnalyticsManager.saveDataAnalytics(
      dataAnalytics,
      eventType
    );
  };

  if (!hasAddedToAmplitude) {
    sendDataAnalytics('SERVICE_UNAVAILABLE_PAGE_VIEWED');
    setHasAddedToAmplitude(true);
  }

  const [screenState, setScreenState] = useState({
    coupon: match.params.couponCode,
    retryCount: 0
  });

  useEffect(() => {
    const validateApiHealthy = async () => {
      const result = await executeStrategy(match.params.sourceErrorEnum, couponCode);
      if (result.success) {
        setRedirectPath(result.path);
      }
    };
    validateApiHealthy();
  }, [couponCode, match.params.sourceErrorEnum]);

  const onRetryClick = async () => {
    setScreenState((previousState) => ({
      ...previousState
    }));
    const result = await executeStrategy(match.params.sourceErrorEnum, couponCode);
    if (result.success) {
      return setRedirectPath(result.path);
    }
    setRetryCount(retryCount + 1);
    sendDataAnalytics(eventTypes.SERVICE_UNAVAILABLE_RETRY_BUTTON_CLICKED);
  };

  const getTop = () => <Image src={imageError} color={null} />;

  const getBottom = () => (
    <>
      <Title title={title} />
      <TextDescription content={retryCount < maxRetries ? description : descriptionWithoutAssets} />
    </>
  );

  const getFooter = () => (
    <Button
      id='service-button-try-again'
      onButtonClick={onRetryClick}
      textFace='Tentar novamente'
    />
  );

  if (screenState.shouldRedirectToPreviousPage) {
    return (<Redirect to={redirectPath} />);
  }

  return (
    redirectPath ? <Redirect to={redirectPath} />
      : (
        <DefaultContainer
          topContent={getTop()}
          bottomContent={getBottom()}
          footerContent={retryCount < maxRetries && getFooter()}
        />
)
  );
};

ServiceNotAvailable.propTypes = {
  match: PropTypes.object.isRequired
};

export default ServiceNotAvailable;
