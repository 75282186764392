const styles = {
  ticket: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    textAlign: 'left',
    '& .content': {
      position: 'absolute',
      top: '0',
      left: '0',
      marginLeft: '3.125%',
      borderRadius: '3px',
      width: '93.055555556%',
      height: '84.375%',
      paddingTop: '7.044117647%',
      paddingBottom: '4.044117647%',
      paddingLeft: '2.941176471%',
      paddingRight: '2.941176471%',
      color: '#fff',
      '&.-small': {
        marginTop: '2.5%',
        height: '86.519298246%',
        paddingTop: '1%'
      },
      '@media (max-width: 690px)': {
        '&.-small': {
          paddingTop: '4.044117647%',
          height: '94.519298246%'
        }
      },
      '@media (min-height: 800px)': {
        paddingTop: '1%',
        marginTop: '4%',
        '&.-small': {
          height: '84.519298246%'
        }
      },
      '& .reward': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
        height: '60%',
        width: '100%',
        '&.-small': {
          height: '60%',
          '@media (min-width: 400px)': {
            height: '60%'
          }
        },
        '& .information': {
          '& >.title': {
            fontSize: '12px',
            fontWeight: '200',
            '@media (min-width: 321px) and (max-width: 399px)': {
              fontSize: '12px'
            },
            '@media (min-width: 400px)': {
              fontSize: '12px'
            }
          },
          '& >.dollar': {
            float: 'left',
            fontSize: '1.375rem',
            fontWeight: '400',
            marginTop: '2.222222222%',
            marginRight: '1.838235294%',
            '&.-small': {
              marginTop: '0px',
              fontSize: '38px',
              lineHeight: '1.2'
            },
            '@media (min-width: 400px)': {
              fontSize: '24px',
              '&.-small': {
                marginTop: '0px',
                fontSize: '24px'
              }
            }
          },
          '& >.value': {
            float: 'left',
            fontSize: '40px',
            lineHeight: '50px',
            fontWeight: '400',
            '&.-small': {
              fontSize: '38px',
              lineHeight: '1.2'
            },
            '@media (min-width: 400px)': {
              fontSize: '48px',
              '&.-small': {
                marginTop: '0px',
                fontSize: '24px'
              }
            },
            '@media (min-width: 600px)': {
              fontSize: '48px',
              '&.-small': {
                marginTop: '0px',
                fontSize: '24px'
              }
            }
          }
        }
      },
      '& .icon': {
        width: '40px',
        height: '40px',
        paddingTop: '1.222222222%',
        paddingBottom: '5.222222222%',
        textAlign: 'right',
        '@media (min-width: 600px)': {
          paddingTop: '3.222222222%'
        }
      }
    },
    '& .couponData': {
      fontSize: '0.875rem',
      height: '40px',
      marginTop: '5.925925926%',
      textTransform: 'uppercase',
      '&.-small': {
        marginTop: '2%'
      },
      '@media (min-width: 400px)': {
        fontSize: '1.13rem'
      },
      '& .subTitle': {
        fontSize: '8px',
        marginBottom: '4px',
        '&.-small': {
          marginBottom: '1%'
        },
        '@media (min-width: 400px)': {
          fontSize: '8px'
        }
      },
      '& .subTitleValue': {
        fontSize: '14px',
        marginBottom: '0px',
        fontWeight: '500',
        '&.-small': {
          marginBottom: '1%',
          fontWeight: '500'
        },
        '@media (min-width: 400px)': {
          fontSize: '14px'
        }
      }
    }
  }
};

export default styles;
