const styles = {
  root: {
    '& label.Mui-focused': {
      color: 'primary'
    },
    '& .MuiOutlinedInput-input': {
      padding: '15.5px 14px'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'primary'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'primary',
        height: '54px'
      },
      '&:hover fieldset': {
        borderColor: 'primary'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'primary'
      }
    },
    '& #phone-number-textField-text-field-helper-text': {
      margin: '0px 14px 0',
      position: 'relative',
      top: '2px'
    }
  },
  containerTextField: {
    display: 'flex',
    justifyContent: 'center',
    '& >.textField': {
      textAlign: 'center',
      height: '56px',
      minHeight: '56px',
      width: '100%',
      '@media (min-width: 650px)': {
        width: '50%'
      }
    }
  }
};

export default styles;
