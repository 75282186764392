import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Routes from '../../../routes';
import Header from '../../organisms/Header/header';
import Footer from '../../organisms/Footer/footer';
import styles from './styles';

const useStyles = makeStyles(styles);

const MainTemplate = () => {
  useStyles();
  const logoAlliance = window.pathLogoAlliance;
  const logoExperience = window.pathLogoExperience;
  return (
    <>
      <Header alliance={logoAlliance} experience={logoExperience} />
      <Container className='containerStyle'>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </Container>
      <Footer />
    </>
  );
};

export default MainTemplate;
