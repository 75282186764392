const styles = {
  descriptionFormatted: {
    padding: '0px 20px 16px',
    textAlign: 'center',
    fontSize: '14px',
    ['@media (min-width: 600px)']: { // eslint-disable-line no-useless-computed-key
      padding: '24x 0px 16px 0px',
      fontSize: '16px'
    }
  }
};

export default styles;
