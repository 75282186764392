/* eslint-disable */

const styles = {
  box:{
    padding: '16px'
  },
  centered: {
    ['@media (min-width: 800px)']: {
      padding: '36px',
      display: 'flex',
      justifyContent: 'center'
    }
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    ['@media (min-width: 800px)']: {
      flexGrow: '0',
      flexBasis: '50%',
      maxWidth: '50%',
      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
    }
  },
  box: {
    padding: '16px'
  },
  buttons:{
    padding: '16px 16px 12px 16px',
    ['@media (min-width: 800px)']: {
      padding: '16px 150px'
    }
  },
  ticketStyle: {
    margin: 'auto',
  },
  ticketGrid: {
    backgroundColor: '#f5f5f5',
    padding: '16px',
    width: '100%'
  },
  '& .contentTicket': {
    backgroundColor: '#f5f5f5',
    padding: '4.715127701%',
    marginTop: '-16px',
    width: '100%'
  },
  '& .info': {
    marginBottom: '16px',
    ['@media (min-height: 600px)']: {
      position: 'relative',
      top: '8px',
      marginBottom: '56px'
    },
  },
  '& .pageItens': {
    padding: '0 4.715127701%',
    '& >p': {
      padding: '0',
      margin: '0 0 1.76056338vh 0'
    },
    '& >.contentAsk': {
      position: 'absolute',
      color: '#959595',
      textTransform: 'uppercase',
      fontWeight: '500',
      fontSize: '0.875rem',
      width: '90%',
      bottom: '21.126760563vh',
      cursor: 'pointer',
      '& >.icon': {
        marginRight: '5px',
        fontSize: '17px',
        lineHeight: '1.3'
      }
    },
    '& >.contentButton': {
      position: 'absolute',
      bottom: '9.85915493vh',
      width: '90%'
    }
  },
  formattedPaper: {
    overflow: 'hidden',
    padding: '0px 16px 16px 16px',
    display: 'block',
    maxHeight: '571px',
    boxShadow: 'none',
    ['@media (min-width: 800px)']: {
      padding: '16px 16px 48px 16px',
      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
    },
    ['@media (min-height: 800px)']: {
      maxHeight: '650px'
    },
    ['@media (min-width: 1200px)']: {
      padding: '16px 16px 16px 16px'
    }
  },
  descriptionStyle: {
    textAlign: 'center',
    paddingBottom: '16px',
    fontSize: '16px'
  }
};

export default styles;
