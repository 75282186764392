import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import Title from '../../atoms/Title/title';
import TextDescription from '../../atoms/TextDescription/textDescription';
import Modal from '../../molecules/Modal/modal';
import Ticket from '../../molecules/Ticket/ticket';
import DefaultContainer from '../../organisms/DefaultContainer/default-container';
import * as AnalyticsManager from '../../../analytics/analyticsManager';
import * as eventTypes from '../../../analytics/eventTypes';
import getCoupon from '../../../clients/xewardsDeliveryCoupon/get';
import styles from './styles';

const useStyles = makeStyles(styles);

const Home = ({ match }) => {
  const classes = useStyles();
  const [disableActivateBonus, setDisableActivateBonus] = useState(true);
  const [errorCoupon, setErrorCoupon] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [hasAddedToAmplitude, setHasAddedToAmplitude] = useState(false);
  const [couponData, setCouponData] = useState({});
  const [consumerData, setConsumerData] = useState({});
  const [pageData, setPageData] = useState({});
  const [redirectToTransactions, setRedirectToTransactions] = useState(false);
  const [redirectToInput, setRedirectToInput] = useState(false);

  const title = consumerData.name ? `Olá ${consumerData.name},` : 'Olá,';
  const description = pageData.description ? pageData.description : 'Acabamos de liberar um cupom para você.'
    + '<br><br> <b>Veja como funciona e aproveite!</b>';
  const defaultMessage = 'Vamos ativar seu voucher agora.';

  const getTop = () => (
    <Title
      title={title}
      subtitle={couponData.appreciationSubtitle || defaultMessage}
    />
  );

  const getCenter = () => (
    <div className={classes.ticketStyled}>
      <Grid container justify='center'>
        <Grid item lg={6} md={6} sm={8} xs={12}>
          <Ticket
            title={couponData.title}
            unit={couponData.unit}
            value={couponData.value}
            expirationDate={couponData.expirationDate}
            couponCode={couponData.couponCode}
            logo={couponData.logo}
          />
        </Grid>
      </Grid>
    </div>
  );

  const getBottom = () => <TextDescription content={description} />;

  const shouldRedirectToTransactions = (coupon) => coupon.status.name !== 'redeemed';
  const modalButtonListener = (modalEvent) => {
    if (modalEvent === 'onClick') {
      return setRedirectToInput(true);
    }
  };

  const getFooter = () => (
    <div className={classes.button}>
      <Modal
        origin='home'
        textButton='Começar'
        disableActivateBonus={disableActivateBonus}
        buttonListener={modalButtonListener}
      />
    </div>
  );

  useEffect(() => {
    const saveDataAnalytics = (data) => {
      if (!hasAddedToAmplitude) {
        const dataAnalytics = AnalyticsManager.setDataToSessionStorage(data);
        AnalyticsManager.saveDataAnalytics(dataAnalytics, eventTypes.COUPON_REDEEMED_PAGE_VIEWED);
        setHasAddedToAmplitude(true);
      }
    };

    const loadContext = async () => {
      const { status, data } = await getCoupon(match.params.couponCode);
      if (status === 200) {
        const { name: alliance } = data._embedded.alliance;
        const { asset } = data.bucket._embedded;
        sessionStorage.setItem('allianceName', alliance);
        if (shouldRedirectToTransactions(data)) {
          return setRedirectToTransactions(true);
        }
        setCouponData((previousState) => ({
            ...previousState,
            title: asset.ticket.title,
            unit: asset.ticket.unit,
            value: asset.ticket.value.toString(),
            expirationDate: data.expirationDate,
            couponCode: data.coupon,
            appreciationSubtitle: asset.appreciationSubtitle,
            logo: asset.ticket.logo
          }));
        setConsumerData((previousState) => ({
            ...previousState,
            mobile: data._embedded.consumer.mobile,
            name: data._embedded.consumer.name
          }));
        setPageData((previousState) => ({
            ...previousState,
            description: asset.appreciationMessage
          }));
        setDisableActivateBonus(false);
        setIsLoading(false);
        saveDataAnalytics(data);
      } else {
        setErrorCoupon(`/error/retryGetCoupons/coupons/${match.params.couponCode}`);
        setIsLoading(false);
      }
    };
    loadContext();
  }, [match.params.couponCode, hasAddedToAmplitude]);
  if (redirectToTransactions) {
    return (<Redirect to={`/transactions/coupons/${match.params.couponCode}`} />);
  }
  if (isLoading) {
    return (
      <div className={classes.page}>
        <CircularProgress color='secondary' />
      </div>
);
  }
  if (errorCoupon) {
    return (<Redirect to={errorCoupon} />);
  }
  if (redirectToInput) {
    return (<Redirect to={`/phonenumber/coupons/${match.params.couponCode}`} />);
  }

  return (
    <DefaultContainer
      topContent={getTop()}
      centerContent={getCenter()}
      bottomContent={getBottom()}
      footerContent={getFooter()}
    />
  );
};

Home.propTypes = {
  match: PropTypes.object
};

Home.defaultProps = {
  match: {}
};

export default Home;
