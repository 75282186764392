import muiTheme from '../../theme/muiTheme';

const styles = {
  titleFormatted: {
    padding: '16px 0px',
    ['@media (min-width: 600px)']: { // eslint-disable-line no-useless-computed-key
      padding: '16px 0px 24px 0px'
    }
  },
  titleColor: {
    color: muiTheme.palette.primary.main
  },
  titleStyle: {
    textAlign: 'center',
    fontSize: '20px',
    color: muiTheme.palette.primary.main
  },
  subtitleStyle: {
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: 1
  }
};

export default styles;
