import muiTheme from '../../theme/muiTheme';

const styles = {
  page: {
    padding: '16px 0px',
    display: 'flex'
  },
  img: {
    paddingBottom: '16px',
    paddingTop: '10px'
  },
  linkStyle: {
    textDecoration: 'none',
    color: muiTheme.palette.primary.main
  },
  text: {
    padding: '16px 0px',
    fontSize: '14px'
  },
  titleStyle: {
    textAlign: 'center',
    color: muiTheme.palette.primary.main,
    padding: '16px 0px'
  },
  descriptionStyle: {
    textAlign: 'center',
    paddingBottom: '16px',
    fontSize: '16px'
  },
  btnStyle: {
    paddingTop: '32px'
  }
};

export default styles;
