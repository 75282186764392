import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useImported } from 'react-imported-component';
import SVG from 'react-inlinesvg';
import styles from './styles';

const ImageFooter = () => {
  const {
    imported: image,
    loading
  } = useImported(() => import('../../../img/byMinu.svg'));
  return (
    !loading && (
      <SVG src={image} />
    )
  );
};

const useStyles = makeStyles(styles);

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <div className='logoXewards'>
        <ImageFooter />
      </div>
    </div>
  );
};

export default Footer;
