import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Image from '../../atoms/Image/image';
import Title from '../../atoms/Title/title';
import Button from '../../atoms/Button/button';
import TextDescription from '../../atoms/TextDescription/textDescription';
import Modal from '../../molecules/Modal/modal';
import DefaultContainer from '../../organisms/DefaultContainer/default-container';
import * as AnalyticsManager from '../../../analytics/analyticsManager';
import * as eventTypes from '../../../analytics/eventTypes';
import imageNumberError from '../../../img/imgNumberError.svg';

const InvalidNumber = ({ match }) => {
  const [hasAddedToAmplitude, setHasAddedToAmplitude] = useState(false);
  const [screenState, setScreenState] = useState({
    coupon: match.params.couponCode,
    shouldRedirectToPhoneNumber: false
  });

  const sendDataAnalytics = (eventType) => {
    const dataAnalytics = AnalyticsManager.getDataFromSessionStorage();

    AnalyticsManager.saveDataAnalytics(
      dataAnalytics,
      eventType
    );
  };
  if (!hasAddedToAmplitude) {
    sendDataAnalytics(eventTypes.INVALID_MOBILE_NUMBER_PAGE_VIEWED);
    setHasAddedToAmplitude(true);
  }

  const redirectToPhoneNumberScreen = () => {
    setScreenState((previousState) => ({
      ...previousState,
      shouldRedirectToPhoneNumber: true
    }));
    sendDataAnalytics(eventTypes.INFORM_NEW_MOBILE_NUMBER_BUTTON_CLICKED);
  };

  const getTop = () => <Image src={imageNumberError} color={null} />;

  const getBottom = () => (
    <>
      <Title title={'Ops, o número é inválido.'} />
      <TextDescription
        content={'Infelizmente não é possível ativar o voucher no número informado.'
        + '<br><br>Confirme novamente as regras para ativação e '
        + 'informe um novo número válido.'}
      />
      <Modal
        origin='invalidNumber'
        disableActivateBonus={false}
        onlyText={true}
        textButton='Para rever o tutorial, clique aqui!'
      />
    </>
  );
  const getFooter = () => (
    <Button
      id='button-input-number'
      onButtonClick={redirectToPhoneNumberScreen}
      textFace='INFORMAR NOVO NÚMERO'
    />
  );
  if (screenState.shouldRedirectToPhoneNumber) {
    return (<Redirect to={`/phonenumber/coupons/${match.params.couponCode}`} />);
  }

  return (
    <DefaultContainer
      topContent={getTop()}
      bottomContent={getBottom()}
      footerContent={getFooter()}
    />
  );
};
InvalidNumber.propTypes = {
  match: PropTypes.object.isRequired
};

export default InvalidNumber;
