import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/app';

import { init } from './analytics/analyticsManager';

init();

ReactDOM.render(
  <App />, document.getElementById('app')
);
