import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { IconButton, Typography, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Tips from '../Tips/tips';
import Button from '../../atoms/Button/button';
import { getDataFromSessionStorage, saveDataAnalytics } from '../../../analytics/analyticsManager';
import {
  SHOW_ACTIVATION_RULES_BUTTON_CLICKED,
  TIPS_SKIP_BUTTON_CLICKED,
  TIPS_CLOSE_BUTTON_CLICKED
} from '../../../analytics/eventTypes';

const styles = () => ({
  root: {
    margin: 0,
    padding: '16px'
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: 'grey'
  }
});

const Dialog = withStyles(() => ({
  root: {
    margin: '0px'
  },
  paperScrollPaper: {
    maxHeight: '100%', // eslint-disable-next-line
    ['@media (min-width: 600px)']: {
      maxHeight: '85vh'
    }
  },
  paper: {
    margin: '0px',
    height: '100vh',
    width: '90vh'
  }
}))(MuiDialog);

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography {...other} style={{ padding: '8px 24px' }}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    margin: '0px',
    padding: '0px 16px',
    paddingTop: '0px'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(() => ({
  root: {
    bottom: '0px',
    margin: '0px',
    display: 'block',
    padding: '16px',
    position: 'absolute',
    width: 'calc(100% - 32px)'
  }
}))(MuiDialogActions);

const Modal = ({
  disableActivateBonus,
  buttonListener,
  onlyText,
  origin,
  textButton
}) => {
  const [isOpenModal, setIsOpen] = useState(false);
  const firstStep = 1;
  const [activeStep, setActiveStep] = React.useState(firstStep);
  const [hasAddedToAmplitude, setHasAddedToAmplitude] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const sendDataAnalytics = (eventType, extraData) => {
    const dataAnalytics = getDataFromSessionStorage();
    saveDataAnalytics(
      dataAnalytics,
      eventType,
      extraData
    );
  };
  const stepsContent = window.tips ? JSON.parse(window.tips.toString().replace(/&quot;/g, '"')) : '';


  const handleClickOpen = () => {
    setIsOpen(true);
    if (buttonListener) {
      buttonListener('open');
    }
    if (!hasAddedToAmplitude) {
      sendDataAnalytics(SHOW_ACTIVATION_RULES_BUTTON_CLICKED, { origin });
      setHasAddedToAmplitude(true);
    }
  };
  const handleClose = (value) => {
    if (buttonListener) {
      buttonListener('close');
    }
    if (value === 'skipButton') {
      sendDataAnalytics(TIPS_SKIP_BUTTON_CLICKED, { step: activeStep });
    } else {
      sendDataAnalytics(TIPS_CLOSE_BUTTON_CLICKED, { step: activeStep });
    }
    setIsOpen(false);
    setActiveStep(firstStep);
  };
  const handleButtonClick = () => {
    const originClick = 'skipButton';
    if (buttonListener) {
      buttonListener('onClick');
    }
    handleClose(originClick);
  };
  const handleChangeActiveStep = (value) => {
    setActiveStep(value);
  };
  const textBtnNextStep = activeStep === (stepsContent.length - 1)
    ? 'Ativar agora' : 'Pular explicação';

  return (
    <>
      <Button
        id='modal-button-activate-bonus'
        disabled={disableActivateBonus}
        onButtonClick={handleClickOpen}
        textFace={textButton}
        onlyText={onlyText}
      />
      <Dialog
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby='Regras de utilização do bônus'
        open={isOpenModal}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose} />
        <DialogContent>
          <Tips tutorialSteps={stepsContent} getActiveStep={handleChangeActiveStep} />
        </DialogContent>
        <DialogActions>
          <Button
            id='modal-button-leave-help'
            onButtonClick={handleButtonClick}
            textFace={textBtnNextStep}
            style={{ position: 'absolute' }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

Modal.propTypes = {
  disableActivateBonus: PropTypes.bool,
  buttonListener: PropTypes.func,
  onlyText: PropTypes.bool,
  origin: PropTypes.string,
  textButton: PropTypes.string
};

Modal.defaultProps = {
  disableActivateBonus: false,
  buttonListener: null,
  onlyText: false,
  origin: '',
  textButton: 'Como ativar'
};

export default Modal;
