export default (state, action) => {
  switch (action.type) {
    case 'DATA_TICKET':
      return {
        ...state,
        title: action.title,
        unit: action.unit,
        value: action.value,
        expirationDate: action.expirationDate,
        couponCode: action.couponCode
      };
    case 'PHONE_NUMBER':
      return {
        ...state,
        phoneNumber: action.phoneNumber
      };
    default:
      return state;
  }
};
